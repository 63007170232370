@import 'antd-theme.css';

:root {
  /* unfortunately variables cannot be used in media queries */
  --lt-sm: 575px;
  --ge-sm: 576px;
  --lt-md: 767px;
  --ge-md: 768px;
  --lt-lg: 991px;
  --ge-lg: 992px;
  --lt-xl: 1199px;
  --ge-xl: 1200px;
  --lt-xxl: 1599px;
  --ge-xxl: 1600px;
  --primary-color-variant-components: 176, 108, 150;
  --primary-color-variant: rgb(var(--primary-color-variant-components));
  --secondary-background-color: #f8f6f7;
  --pdf-color: #aac75d;
  --epub-color: #5e7ec7;
  --audio-color-components: 223, 88, 105;
  --audio-color: rgb(var(--audio-color-components));
  --video-color-components: 57, 146, 154;
  --video-color: rgb(var(--video-color-components));
  --heading-margin: 40px;
  --bookshelf-height: calc(1164px / 2 / 3);
  --bookshelf-thickness: calc(48px / 2);
  --library-item-width: 100px;
  --library-item-top-margin: 12px;
  --library-section-title-height: 36px;
  --media-controls-max-width: 600px;
  --form-spacing: 10px;
  --header-padding: 20px;
  --footer-padding: 20px;
  --footer-icon-font-size: 24px;
}

@font-face {
  font-family: 'Barlow';
  src: url('fonts/Barlow-Regular.ttf');
}

@font-face {
  font-family: 'Barlow';
  src: url('fonts/Barlow-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Barlow';
  src: url('fonts/BarlowCondensed-Regular.ttf');
  font-stretch: condensed;
}

@font-face {
  font-family: 'Barlow';
  src: url('fonts/BarlowCondensed-Medium.ttf');
  font-stretch: condensed;
  font-weight: 500;
}

@media screen and (max-width: 575px) {
  .Hidden-lt-sm {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .Hidden-lt-md {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .Hidden-ge-md {
    display: none !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .Hidden-ge-md-lt-lg {
    display: none !important;
  }
}

body {
  font-family: 'Barlow', 'Arial Narrow', sans-serif;
  overflow-wrap: anywhere;
}

h1,
.Heading-align {
  margin-inline-start: var(--heading-margin);
  margin-inline-end: var(--header-padding);
  text-align: start;
}

h1 {
  text-transform: uppercase;
  font-stretch: condensed;
}

h2 {
  font-stretch: condensed;
}

mark {
  padding: initial;
  font-weight: 500;
  background-color: initial;
}

strong {
  font-weight: 500;
}

.App,
.Center {
  text-align: center;
}

.MainLoading,
.Onboarding-back {
  min-height: 100vh;
}

.MainLoading,
.Media-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Onboarding-back,
.Textured-back {
  background-image: url('images/back.png');
  background-repeat: repeat;
  background-size: 40px;
  flex: auto;
}

.Logo {
  width: 40vmin;
}

.Layout-header {
  display: flex;
  justify-content: flex-end;
  line-height: normal;
  padding-left: var(--header-padding);
  padding-right: var(--header-padding);
  background: white;
}

.Layout-header .Action-button {
  height: initial;
  color: initial;
}

.Layout-sider {
  background: var(--secondary-background-color);
}

.Layout-content {
  display: flex;
  flex-direction: column;
  padding-bottom: calc(var(--footer-icon-font-size) + var(--footer-padding) * 2);
  background: white;
}

@media screen and (max-width: 767px) {
  .Layout-footer {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .Layout-content {
    padding-top: 20px;
  }
  .Layout-footer {
    width: calc(100% - 250px);
  }
}

.Layout-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 2;
  line-height: normal;
  padding-top: var(--footer-padding);
  padding-bottom: var(--footer-padding);
  background: white;
  box-shadow: 0px 0px 6px #0001;
}

.Footer-icon {
  font-size: var(--footer-icon-font-size);
}

.Menu {
  text-transform: uppercase;
  font-weight: 500;
  font-stretch: condensed;
  border: 0;
}

.Content {
  padding: var(--header-padding);
}

.Header {
  display: flex;
  flex-wrap: nowrap;
}

.Header h1 {
  margin-inline-start: initial;
  flex: auto;
}

.Header h1.Center {
  margin-inline-end: var(--heading-margin);
}

.Header .Action-button:not(.Action-back) {
  margin-inline-end: var(--header-padding);
}

.Subheader h1 {
  margin-inline-start: var(--header-padding);
  margin-inline-end: var(--header-padding);
}

.Form {
  margin-left: auto;
  margin-right: auto;
  max-width: 300px;
}

.Last-form-item {
  margin-bottom: initial;
}

.Action,
.Action-label {
  text-transform: uppercase;
  font-size: small;
  font-weight: 500;
  font-stretch: condensed;
}

.Action-button {
  padding: 0;
  overflow: hidden;
}

.Action-icon {
  font-size: 24px;
}

.Action-button .Action-icon {
  line-height: 38px;
}

.Action-back {
  flex: 0 0 40px;
}

.Action-button.Action-back .Action-icon {
  line-height: 34px;
}

.Action-label {
  color: var(--primary-color-variant);
}

.Scanner {
  background: #4d4d4d;
  height: 250px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Scanner video {
  max-width: var(--media-controls-max-width);
  object-fit: cover;
}

.Bookcase-back {
  background-image: url('images/bookcase-back.png');
  background-repeat: repeat;
  background-size: 50px;
  flex: auto;
}

.Bookcase {
  background-image: url('images/bookcase.png');
  background-repeat: repeat;
  background-size: 480px;
}

.Library-item,
.Library-media-item {
  width: 100px;
  height: calc(var(--bookshelf-height) - var(--library-item-top-margin) - var(--bookshelf-thickness));
  margin: var(--library-item-top-margin) 10px var(--bookshelf-thickness) 10px;
  background: var(--primary-color-variant);
  border-radius: 10px;
  overflow: hidden;
  color: white;
}

.Library-item-cover-container {
  display: block;
  margin-bottom: 3px;
}

.Library-item-cover {
  width: 100px;
  height: 92px;
  object-fit: cover;
}

.Library-item-title {
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
  font-weight: 500;
  color: white;
}

.Library-section-title {
  display: table-cell;
  vertical-align: middle;
  height: var(--library-section-title-height);
  padding-inline-start: var(--heading-margin);
  text-transform: uppercase;
  font-size: 1.2em;
  color: white;
}

.Library-media-row {
  overflow-x: scroll;
  scrollbar-width: none; /* Hide scrollbars for Firefox */
  -ms-overflow-style: none; /* Hide scrollbars for IE and Edge */
}

/* Hide scrollbars for Chrome, Safari and Opera */
.Library-media-row::-webkit-scrollbar {
  display: none;
}

.Library-media-item {
  height: calc(var(--bookshelf-height) - var(--library-section-title-height) - var(--bookshelf-thickness));
  margin: 0px 10px var(--bookshelf-thickness) 10px;
}

.Media-icon {
  margin: 4px;
}

.Media-back {
  background: var(--secondary-background-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 8px;
  padding-bottom: 20px;
  margin: 0px 20px;
  flex: auto;
}

.Media-pdf-button {
  height: initial;
  margin-bottom: 10px;
}

.Media-pdf {
  border-radius: 10px;
  overflow: hidden;
  color: initial;
}

.Media-pdf-page {
  border-radius: 10px;
  overflow: hidden;
}

.Media-pdf-modal-container {
  display: flex;
  flex-direction: column;
}

.Media-pdf-modal {
  top: initial;
  margin: auto;
  padding: 20px 0;
}

.Media-pdf-modal .ant-modal-close-x {
  width: 28px;
  height: 28px;
  line-height: 28px;
}

.Media-epub-button {
  height: 350px;
  width: 100%;
  max-width: 250px;
  margin-bottom: 20px;
}

.Media-epub-modal-container {
  display: flex;
  flex-direction: column;
}

.Media-epub-modal {
  margin: auto;
  top: 0px;
  padding-bottom: 0px;
}

.Media-epub-modal .ant-modal-body {
  padding: 0px !important;
}

.Media-epub-modal .ant-modal-close-x {
  width: 28px;
  height: 28px;
  line-height: 28px;
}

.Media-cover {
  height: 150px;
  object-fit: contain;
  border-radius: 10px;
}

.Media-time {
  display: flex;
  justify-content: space-between;
  margin-bottom: -8px;
}

.Media-slider-audio,
.Media-slider-video {
  margin-bottom: 6px;
}

.Media-slider-audio .ant-slider-rail {
  background: rgba(var(--audio-color-components), 0.2);
}

.Media-slider-audio:hover .ant-slider-rail {
  background: rgba(var(--audio-color-components), 0.4);
}

.Media-slider-audio .ant-slider-track {
  background: rgba(var(--audio-color-components), 0.8);
}

.Media-slider-audio:hover .ant-slider-track,
.Media-slider-audio .ant-slider-handle {
  background: var(--audio-color);
}

.Media-slider-audio .ant-slider-handle:focus {
  box-shadow: 0 0 0 5px rgba(var(--audio-color-components), 0.12);
}

.Media-slider-audio .ant-slider-dot {
  border-color: rgba(var(--audio-color-components), 0.4);
}

.Media-slider-video .ant-slider-rail {
  background: rgba(var(--video-color-components), 0.2);
}

.Media-slider-video:hover .ant-slider-rail {
  background: rgba(var(--video-color-components), 0.4);
}

.Media-slider-video .ant-slider-track {
  background: rgba(var(--video-color-components), 0.8);
}

.Media-slider-video:hover .ant-slider-track,
.Media-slider-video .ant-slider-handle {
  background: var(--video-color);
}

.Media-slider-video .ant-slider-handle:focus {
  box-shadow: 0 0 0 5px rgba(var(--video-color-components), 0.12);
}

.Media-slider-video .ant-slider-dot {
  border-color: rgba(var(--video-color-components), 0.4);
}

.Media-slider-volume .ant-slider-rail {
  background: rgba(255, 255, 255, 0.2);
}

.Media-slider-volume:hover .ant-slider-rail {
  background: rgba(255, 255, 255, 0.4);
}

.Media-slider-volume .ant-slider-track {
  background: rgba(255, 255, 255, 0.8);
}

.Media-slider-volume:hover .ant-slider-track,
.Media-slider-volume .ant-slider-handle {
  background: white;
}

.Media-slider-volume .ant-slider-handle:focus {
  box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.12);
}

.Media-slider-audio .ant-slider-handle,
.Media-slider-video .ant-slider-handle,
.Media-slider-volume .ant-slider-handle {
  border: none;
}

.Media-controls,
.Media-settings {
  max-width: var(--media-controls-max-width);
  margin-left: auto;
  margin-right: auto;
}

.Media-controls {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 40px;
  border-radius: 6px;
}

.Media-controls .Action-button {
  color: white;
}

.Media-controls .Action-button[disabled] {
  color: rgba(255, 255, 255, 0.5);
}

.Media-controls .Action-icon svg {
  transition-duration: 0.2s;
}

.Media-controls-volume {
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
}

.Media-slider-volume {
  width: 70px;
}

.Media-settings {
  padding: 20px 10px 0 10px;
  border-collapse: separate;
  border-spacing: 5px 10px;
  white-space: nowrap;
  text-align: start;
}

.Media-setting-slider-cell {
  width: 100%;
}

.Media-setting-slider-cell .Media-slider-audio,
.Media-setting-slider-cell .Media-slider-video {
  margin-bottom: 10px;
}

.Media-setting-slider .ant-tooltip {
  padding: 0;
}

.Media-setting-slider .ant-tooltip-inner {
  padding: 0;
  margin-bottom: 1px;
  min-height: initial;
  background: initial;
  box-shadow: initial;
  text-align: center;
  line-height: 1;
}

.Media-setting-slider .ant-tooltip-arrow {
  display: none;
}

.Media-setting-slider .ant-zoom-down-appear {
  transform: initial;
  opacity: initial;
  animation: none;
}

.Media-list-item,
.Media-list-current-item,
.Media-list-item-data {
  display: flex;
  align-items: center;
}

.Media-list-item,
.Media-list-current-item {
  width: calc(100% - 6px);
  max-width: calc(var(--media-controls-max-width) - 6px);
  height: 34px;
  border-radius: 6px;
  margin: 20px auto 0;
  text-align: start;
}

.Media-list-item {
  background: rgba(var(--primary-color-variant-components), 0.6);
}

.Media-list-current-item {
  background: var(--primary-color-variant);
}

.Media-list-item-link,
.Media-list-current-item .Media-list-item-data {
  flex-grow: 1;
  min-width: 0px;
}

.Media-list-item-data,
.Media-list-item .Action-button,
.Media-list-current-item .Action-button {
  color: white;
}

.Media-list-item .Action-button.ant-btn-loading::before,
.Media-list-current-item .Action-button.ant-btn-loading::before {
  background: initial;
}

.Media-list-item-data .Media-icon {
  margin-inline: 10px;
}

.Media-list-item-data .Media-icon,
.Media-list-item .Action-button,
.Media-list-current-item .Action-button {
  flex-shrink: 0;
}

.Media-list-item-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Text-pdf,
.Text-pdf:hover,
.Text-pdf:focus {
  color: var(--pdf-color);
}

.Text-epub,
.Text-epub:hover,
.Text-epub:focus {
  color: var(--epub-color);
}

.Text-audio,
.Text-audio:hover,
.Text-audio:focus,
.Media-setting-slider.Media-slider-audio .ant-tooltip-inner {
  color: var(--audio-color);
}

.Text-video,
.Text-video:hover,
.Text-video:focus,
.Media-setting-slider.Media-slider-video .ant-tooltip-inner {
  color: var(--video-color);
}

.Element-pdf,
.Element-pdf:hover,
.Element-pdf:focus {
  background: var(--pdf-color);
}

.Element-epub,
.Element-epub:hover,
.Element-epub:focus {
  background: var(--epub-color);
}

.Element-audio,
.Element-audio:hover,
.Element-audio:focus {
  background: var(--audio-color);
}

.Element-video,
.Element-video:hover,
.Element-video:focus {
  background: var(--video-color);
}

.Playlist-data {
  text-align: start;
  line-height: 1.1;
}

.Playlist-data :not(mark) {
  color: initial;
}

.Account-avatar {
  width: 70px !important;
  height: 70px !important;
}

.Account-details {
  display: inline-block;
}

.Device-data {
  padding-top: 6px;
  text-align: start;
  line-height: 1.1;
}

.BackOffice .Content {
  text-align: left;
  padding: var(--header-padding) var(--heading-margin);
}

@media screen and (max-width: 767px) {
  .BackOffice .Content {
    text-align: left;
    padding: var(--header-padding);
  }

  .BackOffice .ant-table {
    font-size: 80% !important;
  }
}
